var checkin, checkout, checkinInline, checkoutInline;

$(document).ready( function () {
	
	$('.booking-bar-open, .close-booking, .booking-bar-close').on('click', function() {
		$('.booking-bar').toggleClass('open');
		
		if ( $('.booking-bar').hasClass('open') ) {
			$('.booking-bar').css('height', window.innerHeight - 30);
			setupBookingBar();
		} else {
			$('.booking-bar').css('height', 0);
		}
	});
	
	// Always stretch the booking bar to fill the screen
	$(window).on('resize', _.throttle(function () {
		if ( $('.booking-bar').hasClass('open') ) {
			$('.booking-bar').css('height', window.innerHeight - 30);
			setupBookingBar();
		}
	}, 100));
	
	
	
	
	// Change the normal selects when the fake ones change
	$('.drop-select button').on('click', function () {
		$(this).siblings().removeClass('selected');
		$(this).addClass('selected');
		
		$(this).parent().prev('select').val( $(this).text() );
		
		return false;
	});
	
	// Update the fake selects when the normal ones change
	$('.booking-bar select').on('change', function () {
		$(this).next('.drop-select').children().removeClass('selected');
		$(this).next('.drop-select').children('[data-value="' + $(this).val() + '"]').addClass('selected');
	});
	
	
	
	
	
	// Useful docs for the strange datepicker http://t1m0n.name/air-datepicker/docs/
	function setupBookingBar() {
		$.fn.datepicker.language['en'] = {
		    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		    daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		    daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
		    months: ['January','February','March','April','May','June', 'July','August','September','October','November','December'],
		    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		    today: 'Today',
		    clear: 'Clear',
		    dateFormat: 'yyyy-mm-dd',
		    timeFormat: 'hh:ii aa',
		    firstDay: 0
		};
		
		var hotelOpens = new Date('2018-09-01T00:00:00');
		var nowTemp = new Date();
		var now;
		
		if ( nowTemp > hotelOpens ) {
			now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
		} else {
			now = new Date(hotelOpens.getFullYear(), hotelOpens.getMonth(), hotelOpens.getDate(), 0, 0, 0, 0);
		}
		
		
		/*
		 * INLINE DATEPICKERS SHOW FOR SCREENS ABOVE 1244PX AND ABOVE
		 */
		checkinInline = $('#check-in-inline').datepicker({
			inline: true,
			language: 'en',
			minDate: now,
			onRenderCell: function (date, cellType) {
				return date.valueOf() < now.valueOf() ? {html: '', classes: '', disabled: true} : {html: '', classes: '', disabled: false};
			},
			onSelect: function (formattedDate, date, inst) {
				var newDate = new Date(date)
				newDate.setDate(newDate.getDate() + 1);
				if (date.valueOf() > checkoutInline.currentDate.valueOf()) {
					checkoutInline.date = newDate;
					checkoutInline.selectDate(newDate);
				}
				checkoutInline.update('minDate', newDate);
				$('input[name="arrive"]').val(formattedDate);
			}
		}).data('datepicker');
				
		checkoutInline = $('#check-out-inline').datepicker({
			inline: true,
			language: 'en',
			minDate: now,
			onRenderCell: function (date, cellType) {
				return date.valueOf() <= checkinInline.currentDate.valueOf() ? {html: '', classes: '', disabled: true} : {html: '', classes: '', disabled: false};
			},
			onSelect: function (formattedDate, date, inst) {
				$('input[name="Depart"]').val(formattedDate);
			}
		}).data('datepicker');
		
		
		
		/*
		 * HIDDEN DATEPICKERS SHOW FOR SCREENS BELOW 1244PX
		 */
		checkin = $('#check-in').datepicker({
			language: 'en',
			minDate: now,
			onRenderCell: function (date, cellType) {
				return date.valueOf() < now.valueOf() ? {html: '', classes: '', disabled: true} : {html: '', classes: '', disabled: false};
			},
			onSelect: function (formattedDate, date, inst) {
				var newDate = new Date(date)
				newDate.setDate(newDate.getDate() + 1);
				if (date.valueOf() > checkout.currentDate.valueOf()) {
					checkout.date = newDate;
					checkout.selectDate(newDate);
				}
				checkout.update('minDate', newDate);
				$('input[name="arrive"]').val(formattedDate);
				$('#check-out')[0].focus();
			}
		}).data('datepicker');
		
		checkin.update('inline', false);
		
		checkout = $('#check-out').datepicker({
			language: 'en',
			minDate: now,
			onRenderCell: function (date, cellType) {
				return date.valueOf() <= checkin.currentDate.valueOf() ? {html: '', classes: '', disabled: true} : {html: '', classes: '', disabled: false};
			},
			onSelect: function (formattedDate, date, inst) {
				checkout.hide();
				$('input[name="Depart"]').val(formattedDate);
			}
		}).data('datepicker');
	}
	
	
	
	
	
});


